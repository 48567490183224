import React, {useCallback, useEffect, useState} from 'react';
import './noticeView.scss';
import {
    ALERTIFY_JS_URL, ALERTIFY_STYLE_URL, COMMENT_SE_JS_URL, COMMENT_SE_STYLE_URL, EMOTICON_BOOTSTRAP_JS_URL,
    IHeaderProps, IUserInfoProps, POPPER_JS_URL, SEARCH_BBS_URL,
    SEARCH_SCHOOL_INFO_URL, ServiceType,
} from '../../app.constant';
import axios from 'axios';
import {
    checkUserInfo,
    getQueryString,
    getToken, loadScript, loadStyle,
} from '../../service/common';
import Header from '../header/header';
import Content from '../content/content';
import ProgressBar from '../progreesBar/progressBar';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {useGetUserData} from '../../hooks/useAuth';
import {useRecoilValue} from 'recoil';
import {userInfoAtom} from '../../atoms/atoms';

const NoticeView: React.FC<any> = (props) => {
    // 유저 정보
    useGetUserData();
    const userInfo = useRecoilValue<IUserInfoProps>(userInfoAtom);

    // 해더 정보 [타입, 제목, 날짜]
    const [headerData, setHeaderData] = useState<IHeaderProps>();

    // 컨텐츠 정보 [작성자, 받는 사람, 글 내용, 기간]
    const [contentData, setContentData] = useState({
        type: '',
        title: '',
        date: ''
    } as any);

    // 로딩 활성화
    const [isLoad, setIsLoad] = useState(false);

    // 공지 데이터 가져오기
    const getNoticeData = useCallback(async (): Promise<any> => {
        const streamId = window.location.pathname.split('/').pop() as string;
        const token = getToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const response =
            await axios.get(`${SEARCH_BBS_URL}`.replace(':streamId', streamId) + `?${getQueryString()}`);
        const noticeData = response.data.data as any;

        // 학교 정보 데이터 가져오기
        if (noticeData && noticeData.schoolIdx) {
            const response = await axios.get(`${SEARCH_SCHOOL_INFO_URL}?schoolSeqs=${noticeData.schoolIdx}`);
            if (response && response.status === 200 && response.data && response.data.result && response.data.data && response.data.data.length > 0) {
                setHeaderData((prev: any) => {
                    return {
                        ...prev,
                        schoolName: response.data.data[0].schoolName,
                    }
                })
            }
        }

        setHeaderData((prev: any) => {
            return {
                ...prev,
                bAdminRecommend: noticeData.bAdminRecommend,
                name: noticeData.name,
                type: noticeData.streamType,
                title: noticeData.title,
                date: noticeData.writeDate,
                streamId: noticeData.id,
                isNew: noticeData.isNew ? noticeData.isNew : false,
                comment: noticeData.commentAuth,
            }
        });

        // 컨텐츠 정보 셋팅
        if (noticeData.otherUploadList) {
            noticeData.uploadList = noticeData.otherUploadList;
        }

        setContentData({
            writer: noticeData.senderName || '',
            receiver: noticeData.stName ? `${noticeData.stSchoolName} ${noticeData.stParentClsName}
            ${noticeData.stClsName} ${noticeData.stName}` : '',
            content: noticeData.contents,
            files: noticeData.uploadList,
        })


        if (noticeData.commentAuth === '1' && noticeData.streamType === 'classBoard') {
            await loadStyle(COMMENT_SE_STYLE_URL);
            await loadStyle(ALERTIFY_STYLE_URL);
            await loadScript(ALERTIFY_JS_URL);
            await loadScript(COMMENT_SE_JS_URL);
            await loadScript(POPPER_JS_URL);
            await loadScript(EMOTICON_BOOTSTRAP_JS_URL);
            (window as any)._coolineCommentSeApp.bootstrap({
                streamId: noticeData.streamId,
                serviceType: ServiceType.ALIMI,
                authorization: token,
                isNeedLogin: true
            });
        }

        setIsLoad(true);
    }, [])

    // 랜더링할 때 실행
    useEffect(() => {
        getNoticeData().then();
    },[getNoticeData])

    return (
        <div className="survey-view pb55" onClick={() => {checkUserInfo(userInfo)}}>
            {isLoad ? null : <div className='loading-box'>
                <div style={{ width: 30, height: 30 }} className='flex'>
                    <ProgressBar values={[0, 100]} repeat={true} speed={400}>
                        {(percentage: any) => (
                            <CircularProgressbar
                                value={percentage}
                                strokeWidth={20}
                                styles={buildStyles({
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}/>
                        )}
                    </ProgressBar>
                </div>
            </div>}
            <meta name="viewport"
                  content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>
            {/*해더 정보*/}
            <Header {...headerData} />
            {/*설문 내용 컴포넌트*/}
            <Content {...contentData} />

            {headerData?.comment === '1' ? <><br/><br/><div id="comment-app"></div></> : null}

        </div>
    )
}
export default NoticeView;
