import React, {useEffect, useState} from 'react';

type progressProps = {
    children?: any,
    values?: any,
    speed?: number,
    repeat?: boolean
}

const ProgressBar: React.FC<progressProps> = ({children, values, speed = 250, repeat = false}) => {

    const [valuesIndex, setValueIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setValueIndex(prevValue => (prevValue + 1) % values.length)
        }, speed);

        if (!repeat) {
            setTimeout(() => {
                clearInterval(interval);
            }, 1500)
        }

    }, [values.length, repeat, speed])

    return (children(values[valuesIndex]))
}

export default ProgressBar;
