import React, {Suspense, useCallback, useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {
    IHeaderProps, ISignatureParams, ISignatureProps, ISurvey, ISurveyAnswer, ISurveyInfoProps, IUserInfoProps,
    MEMBER_SIGN_GET_URL_API,
    SEARCH_BBS_URL,
    SEARCH_COOLSUBSCRIBER_SAVE_URL,
    ServiceType,
    SubServiceType, SurveyType,
} from '../../app.constant';
import Survey from '../survey/survey';
import Header from '../header/header';
import Content from '../content/content';
import './surveyView.scss';
import {useLocation, useNavigate} from 'react-router-dom';
import ReactSignatureCanvas from 'react-signature-canvas';
import ProgressBar from '../progreesBar/progressBar';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {useRecoilState, useRecoilValue} from 'recoil';
import {answerAtom, userInfoAtom} from '../../atoms/atoms';
import {
    checkUserInfo,
    getParam,
    getQueryString,
    getSurveyDate,
    getToken, signUpload,
    validation
} from '../../service/common';
import {useGetUserData} from '../../hooks/useAuth';
declare const moment: any;

const SurveyView: React.FC<any> = () => {
    useGetUserData();
    const userInfo = useRecoilValue<IUserInfoProps>(userInfoAtom);
    const [answerData, setAnswerData] = useRecoilState<ISurveyAnswer[]>(answerAtom);
    const sigCanvas = useRef<any>(null);
    const [signatureInfo, setSignatureInfo] = useState<ISignatureProps>({
        signatureUrl: "",
        isSignatureCheck: false,
        isValidationSignCheck: false,
        isSignWrite: false,
        showSign: false,
        signBtnToggle: false
    })
    const navigate = useNavigate();
    const location = useLocation();
    const [msgId, setMsgId] = useState(getParam('msgId'));
    const [contactId, setContactId] = useState(getParam('contactId'));
    const [metaId, setMetaId] = useState(getParam('metaId'))
    const [surveyInfo, setSurveyInfo] = useState<ISurveyInfoProps>({
        isSurveyTerm: true,
        surveyTermTxt: '회신하기',
        isValidationCheck: false,
        surveyData: [] as ISurvey[],
    })
    const [headerData, setHeaderData] = useState<IHeaderProps>();
    const [contentData, setContentData] = useState({
        type: '',
        title: '',
        date: ''
    } as any);
    const [isLoad, setIsLoad] = useState(false);

    /** 설문 데이터 가져오기 */
    const getSurveyData = useCallback(async (): Promise<any> => {
        try {
            const streamId = location.pathname.split('/').pop() as string;
            const token = getToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response
                = await axios.get(`${SEARCH_BBS_URL}`.replace(':streamId', streamId) + `?${getQueryString()}`);
            const surveyData = response.data.data;

            checkSurveyTerm(surveyData);

            const params = {
                serviceType: ServiceType.ALIMI,
                subServiceType: SubServiceType.SURVEY_RESPONSE,
                etc1: msgId,
                streamId,
                contactId,
            } as any;
            const {startDate, endDate, startDateHour, startDateMinute, endDateHour, endDateMinute, } =
                getSurveyDate([surveyData.surveyStartDate.toString(), surveyData.surveyEndDate.toString()]);

            // 해더 정보 셋팅
            setHeaderData({
                type: surveyData.streamType,
                title: surveyData.title,
                date: surveyData.writeDate,
                streamId: surveyData.id,
                surveyStartDate: startDate.slice(0, 8),
                surveyEndDate: endDate.slice(0, 8),
                isResponse: surveyData.isResponse,
            })

            // 컨텐츠 정보 셋팅
            if (surveyData.otherUploadList) {
                surveyData.uploadList = surveyData.otherUploadList;
            }

            // 컨텐츠 정보 셋팅
            setContentData({
                writer: surveyData.senderName || '',
                receiver: surveyData.stName ? `${surveyData.stSchoolName} ${surveyData.stParentClsName}
            ${surveyData.stClsName} ${surveyData.stName}` : '',
                content: JSON.parse(surveyData.contents).formContents,
                term: `${moment(startDate.slice(0, 8)).format(`YYYY.MM.DD ${startDateHour}:${startDateMinute}`)} ~
            ${moment(endDate.slice(0, 8)).format(`YYYY.MM.DD ${endDateHour}:${endDateMinute}`)}`,
                files: surveyData.uploadList
            })

            // msgId, contactId 둘중 하나라도 없으면 기본 설문 데이터 보여주기
            const surveyContents = JSON.parse(surveyData.contents).surveyContents;
            for (const data of surveyContents) {
                if (data && data.etcQuestion && (data.surveyType === SurveyType.singleMulti || data.surveyType === SurveyType.singleSingle)) {
                    data.surveyQuestion.push({isChecked: false, content: '', etc: true});
                }
            }
            if (!msgId || !contactId) {
                setSurveyInfo(prev => {return {...prev, surveyData: surveyContents}})
                setAnswerData(surveyContents);
                return;
            }

            // 설문 응답했는지 조회 후 결과에 따라 데이터 뿌려주기
            const surveyLookUp = await axios.post(`${SEARCH_COOLSUBSCRIBER_SAVE_URL}?isJoin=true`, params) as any;
            if (!surveyLookUp.data.result) {
                setAnswerData(surveyContents);
                setSurveyInfo(prev => {return {...prev, surveyData: surveyContents}})
                if (surveyData.signature === '1') setSignatureInfo(prev => {return {...prev, isSignWrite: true, isSignatureCheck: true}})

                // db에 저장된 서명이 있는지 확인, 있을 경우 가져오기, 서명가져오기 버튼 보여주기
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const dbSignResult = await axios.get(`${MEMBER_SIGN_GET_URL_API}?target=sign`);
                if (dbSignResult.status === 200 && dbSignResult.data &&
                    dbSignResult.data.result === true &&
                    dbSignResult.data.data) {
                    setSignatureInfo((prev: ISignatureProps) => {
                        return {...prev, signatureUrl: dbSignResult.data.data}
                    })
                }
                return;
            } else {
                // 설문 정보 셋팅
                const lookupData = surveyLookUp.data.data.contents.answerData;
                setAnswerData(lookupData);
                setSurveyInfo(prev => {return {...prev, surveyTermTxt: '다시 회신하기', surveyData: lookupData}})

                if (surveyData.signature === '1') {
                    // db에 저장된 서명이 있는지 확인, 있을 경우 가져오기, 서명하기 버튼 보여주기
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    const dbSignResult = await axios.get(`${MEMBER_SIGN_GET_URL_API}?target=sign`);
                    if (dbSignResult.status === 200 && dbSignResult.data &&
                        dbSignResult.data.result === true &&
                        dbSignResult.data.data) {
                        setSignatureInfo((prev: ISignatureProps): ISignatureProps => {
                            return {
                                ...prev,
                                signBtnToggle: true,
                                showSign: true,
                                signatureUrl: dbSignResult.data.data,
                                isSignatureCheck: true
                            }
                        })
                    }
                }

            }
        } finally {
            setIsLoad(true);
        }
    }, [headerData?.streamId, location.pathname])

    /** 설문 응답 보내기 */
    const sendSurvey = async (): Promise<any> => {

        setIsLoad(false);

        // 로그인 정보가 없을 경우 리턴
        const token = getToken();
        if (!token || !userInfo?.data?.result || !userInfo?.data?.data) {
            setIsLoad(true);
            return;
        }

        // 섦룬 문제 체크
        const validationCheck = validation(answerData);
        setSurveyInfo((prev: ISurveyInfoProps) => {return {...prev, isValidationCheck: !validationCheck}})
        if (!validationCheck) {
            // alert('설문을 확인해주세요.');
            setIsLoad(true);
            return;
        }

        const {isSignatureCheck, isSignWrite, signatureUrl, showSign} = signatureInfo;
        if (isSignatureCheck && isSignWrite) { // 서명 이미지 x, 서명 캔버스 o
            const temSignUrl = sigCanvas.current.toDataURL('image/png');
            if (sigCanvas.current.getSignaturePad().points.length === 0) {
                setSignatureInfo(prev => {return {...prev, isValidationSignCheck: true}})
                setIsLoad(true);
                return;
            }
            const signatureParams: ISignatureParams = {
                serviceType: ServiceType.ALIMI,
                fileName: `${userInfo.data.data.idx}_sign.png`,
                imgData: temSignUrl,
            };
            await sendSurveyData(true, signatureParams);
            return;
        } else if (isSignatureCheck && signatureUrl && showSign) { // 서명 이미지 o, 서명 캔버스 x
            await sendSurveyData(false, {});
            return;
        } else { // 서명 이미지 x, 서명 캔버스 x
            await sendSurveyData();
        }
    }

    /** 서명 보내기 */
    const sendSurveyData = async (isSign = false, signatureParams?: ISignatureParams): Promise<any> => {
        setSignatureInfo(prev => {return {...prev, isValidationSignCheck: false}});

        // msgId 없으면 막기
        if (!msgId || !contactId) {
            alert('테스트 작업 완료 되었습니다.');
            return;
        }

        await signUpload(isSign, signatureInfo, userInfo, signatureParams,);
        const params = {
            contents: JSON.stringify({answerData}),
            serviceType: ServiceType.ALIMI,
            subServiceType: SubServiceType.SURVEY_RESPONSE,
            streamId: headerData?.streamId,
            etc1: msgId,
            contactId: contactId,
        } as any;

        if (metaId && !isNaN(parseInt(metaId))) {
            params.metaId = metaId;
        }

        const response = await axios.post(SEARCH_COOLSUBSCRIBER_SAVE_URL, params) as any;

        // 참여 중복 체크
        if (response.status === 200 && !response.data.result) {
            setIsLoad(true);
            alert(response.data.message);
            return;
        }

        const win = window as any;
        if (win.ReactNativeWebView) {
            win.ReactNativeWebView.postMessage(
                JSON.stringify({type: 'REQ_ALIMI_SURVEY_DONE', streamType: headerData?.type, id: headerData?.streamId})
            );
        }

        if (response.status === 200 && response.data.result) {
            navigate(`/result/${headerData?.streamId}?msgId=${msgId}&contactId=${contactId}&metaId=${metaId}`);
        }
    }

    /** 서명 초기화 */
    const clear = () => {
        sigCanvas.current.clear();
    }

    /** 설문 기간 체크 */
    const checkSurveyTerm = (data: any) => {
        const toDate = moment(new Date()).format('YYYYMMDDHHmm');
        if (!(data.surveyEndDate.toString() >= toDate.toString())) {
            setSurveyInfo(prev => {return {...prev, isSurveyTerm: false, surveyTermTxt: '회신마감'}});
        }
        if (!(data.surveyStartDate.toString() <= toDate.toString())) {
            setSurveyInfo(prev => {return {...prev, isSurveyTerm: false, surveyTermTxt: '설문 기간이 아닙니다'}});
        }
    }

    /** 설문 정보 리스트 */
    const surveyDataList = surveyInfo.surveyData.map((survey: ISurvey, index: number) => {
        return (
            <Survey key={index} index={index} />
        )
    })

    // 랜더링할 때 실행
    useEffect(() => {
        getSurveyData().then();
    }, [])

    const {signatureUrl, isSignatureCheck, isValidationSignCheck, isSignWrite, showSign, signBtnToggle} = signatureInfo;
    return (
        <div className="survey-view pb55" onClick={() => {checkUserInfo(userInfo)}}>
            {isLoad ? null : <div className='loading-box'>
                <div style={{ width: 30, height: 30 }} className='flex'>
                    <ProgressBar values={[0, 100]} repeat={true} speed={400}>
                        {(percentage: any) => (
                            <CircularProgressbar
                                value={percentage}
                                strokeWidth={20}
                                styles={buildStyles({
                                    pathTransition:
                                        percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                })}/>
                        )}
                    </ProgressBar>
                </div>
            </div>}

            <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"/>

            {/*해더 정보*/}
            <Header {...headerData} />
            {/*설문 내용 컴포넌트*/}
            <Content {...contentData} />
            <div className="relative">
                {/*설문 응답 컴포넌트*/}
                <Suspense fallback={<div>로딩중....</div>}>
                    {surveyDataList}
                </Suspense>

                {/*서명 컨버스*/}
                {signatureInfo.isSignatureCheck && isSignWrite ?
                    <>
                        {/*서명*/}
                        <div className="signature-box mt50">
                            <div className="head">
                                <p className='signature-p' onClick={() => {$('.signature-p').hide()}}>이곳을 눌러 서명해 주세요.</p>
                                <svg onClick={clear} className="cP" xmlns="http://www.w3.org/2000/svg" width="40"
                                     height="40" viewBox="0 0 40 40">
                                    <rect data-name="사각형 113" width="40" height="40" fill="#f2e2e2" opacity="0"/>
                                    <g transform="translate(-123.807 -123.318)">
                                        <g transform="translate(144.184 133.545) rotate(45)" fill="#fff" stroke="#000"
                                           strokeWidth="1">
                                            <rect width="10.056" height="16.089" rx="2" stroke="none"/>
                                            <rect x="0.5" y="0.5" width="9.056" height="15.089" rx="1.5" fill="none"/>
                                        </g>
                                        <line x2="6" y2="6" transform="translate(138.307 140.818)" fill="none" stroke="#000"
                                              strokeWidth="1"/>
                                        <line x2="14.489" transform="translate(134.5 150.989)" fill="none" stroke="#000"
                                              strokeLinecap="round" strokeWidth="1"/>
                                    </g>
                                </svg>
                            </div>
                            <ReactSignatureCanvas
                                ref={sigCanvas}
                                canvasProps={{
                                    className: 'signatureCanvas'
                                }}
                            />
                        </div>
                    </> : null
                }

                {/*서명 이미지*/}
                {
                    signatureUrl && isSignatureCheck && showSign ? (
                        <img
                            className='signature-img mt50'
                            src={signatureUrl}
                            alt="img"
                        />
                    ) : null
                }

                {
                    isSignatureCheck ? (
                        <div className='signature-btn-box mt15'>
                            {/*서명 버튼*/}
                            {
                                signBtnToggle ? (
                                    <span className='signature-btn mr10'
                                          onClick={() => {
                                              setSignatureInfo(prev => {return {
                                                  ...prev,
                                                  isSignWrite: true,
                                                  showSign: false,
                                                  signBtnToggle: !prev.signBtnToggle
                                              }})
                                          }}
                                    >서명편집</span>
                                ) : null
                            }
                            {/*서명 불러오기 버튼*/}
                            {
                                signatureUrl && !signBtnToggle ? (
                                    <span className='signature-btn blue-btn'
                                          onClick={() => {
                                              setSignatureInfo(prev => {return {
                                                  ...prev,
                                                  isSignWrite: false,
                                                  showSign: true,
                                                  isValidationSignCheck: false,
                                                  signBtnToggle: !prev.signBtnToggle
                                              }})
                                          }}
                                    >서명 불러오기</span>
                                ) : null
                            }
                        </div>
                    ) : null
                }

                <div className='mt20'>
                    {/*설문 체크*/}
                    {
                        surveyInfo.isValidationCheck ? (
                            <p className='warn'>필수 답변이 누락된 항목이 있습니다.</p>
                        ) : null
                    }

                    {/*서명 체크*/}
                    {
                        isValidationSignCheck ? (
                            <p className='warn'>서명이 누락되었습니다.</p>
                        ) : null
                    }
                </div>

                {
                    surveyInfo.isSurveyTerm ? (
                        <span className="send-btn mt50" onClick={sendSurvey}>{surveyInfo.surveyTermTxt}</span>
                    ) : <span className="send-btn mt50 btn-gray">{surveyInfo.surveyTermTxt}</span>}

                {
                    !surveyInfo.isSurveyTerm ? (
                        <div className='block-div'></div>
                    ) : null
                }
            </div>
        </div>
    )
}

export default React.memo(SurveyView);
