import React, {useCallback, useEffect, useRef} from 'react';
import './survey.scss';
import Datepicker from '../datepicker/datepicker';
import {IAnswer, ISurveyAnswer, SurveyType} from '../../app.constant';
import {useRecoilState} from 'recoil';
import {answerAtom} from '../../atoms/atoms';

type surveyNumber = {
    index: number;
}

const Survey = ({index}: surveyNumber) => {
    const [answerData, setAnswerData] = useRecoilState<ISurveyAnswer[]>(answerAtom);
    const inputRef = useRef<any>(null);
    const singleEtc = useRef<any>(null);
    const multiEtc = useRef<any>(null);
    const textareaRef = useRef<any>(null);

    /** 객관식 단항 */
    const setSingleSingleAnswer = useCallback((qIndex: number, data: React.ChangeEvent<HTMLInputElement>) => {
        const copyAnswerQ: IAnswer[] = [...(answerData[index]?.surveyQuestion ?? [])];
        copyAnswerQ?.forEach((item, index) => {
            copyAnswerQ[index] = {
                ...copyAnswerQ[index],
                isChecked: index === qIndex ? data.target.checked : false,
            }
        })

        /** 체크한 문항이 기타 유무에 따라 기타 답변 disabled */
        if (singleEtc.current && copyAnswerQ[qIndex].etc) {
            ($(singleEtc.current) as any).attr('disabled', false);
        } else if (singleEtc.current) {
            ($(singleEtc.current) as any).attr('disabled', true);
            copyAnswerQ[($(singleEtc.current) as any).attr('data-index')].content = '';
        }

        setAnswerData((prev: ISurveyAnswer[]): ISurveyAnswer[] =>
            prev.map((item: ISurveyAnswer, idx: number): ISurveyAnswer =>
                idx === index ? {...item, surveyQuestion: copyAnswerQ} : item)
        )
    }, [answerData[index]])

    /** 객관식 다항 */
    const setSingleMultiAnswer = useCallback((qIndex: number, data: React.ChangeEvent<HTMLInputElement>) => {
        const surveyQ:IAnswer[] = [...(answerData[index]?.surveyQuestion ?? [])];
        surveyQ[qIndex] = {
            ...surveyQ[qIndex],
            isChecked: data.target.checked,
        };

        // 체크한 문항이 기타 유무에 따라 기타 답변 disabled
        if (surveyQ[qIndex].isChecked && surveyQ[qIndex].etc) {
            ($(multiEtc.current) as any).attr('disabled', false);
        } else if (!surveyQ[qIndex].isChecked && surveyQ[qIndex].etc) {
            ($(multiEtc.current) as any).attr('disabled', true);
            surveyQ[($(multiEtc.current) as any).attr('data-index')].content = '';
        }

        setAnswerData((prev: ISurveyAnswer[]): ISurveyAnswer[] =>
            prev.map((item: ISurveyAnswer, idx: number): ISurveyAnswer =>
                index === idx ? {...item, surveyQuestion: surveyQ} : item)
        );
    }, [answerData[index]])

    /** 객관식 기타 문항 */
    const setEtcAnswer = useCallback((qIndex: number, data: React.ChangeEvent<HTMLTextAreaElement>) => {
        const surveyQ = [...answerData[index]?.surveyQuestion ?? []];
        surveyQ[qIndex] = {
            ...surveyQ[qIndex],
            content: data.target.value,
        }
        setAnswerData((prev: ISurveyAnswer[]): ISurveyAnswer[]  =>
            prev.map((item: ISurveyAnswer, idx: number): ISurveyAnswer =>
                index === idx ? {...item, surveyQuestion: surveyQ} : item)
        );
    }, [answerData[index]])

    /** 주관식 */
    const setTextAnswer = useCallback((data: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setAnswerData((prev: ISurveyAnswer[]) =>
            prev.map((item: ISurveyAnswer, idx: number): ISurveyAnswer =>
                index === idx ? {...item, answer: data.target.value} : item)
        );
    }, [answerData[index]])

    /** 객관식 단일 선택 리스트 */
    const singleSingleList = answerData[index]?.surveyType === SurveyType.singleSingle ?
        (answerData[index]?.surveyQuestion ?? []).map((data: IAnswer, qIndex: number) => {
            return (
                <div key={qIndex} className={data.etc ? 'q-box q-etc' : 'q-box'}>
                    <label className={!data.etc ? 'radio flexInline' : 'radio dpBK etc'} htmlFor={'radio_' + index + '_' + qIndex }>
                        <input type="radio"
                               id={'radio_' + index + '_' + qIndex}
                               name={'radio_' + index}
                               checked={!!data.isChecked}
                               onChange={(e) => {setSingleSingleAnswer(qIndex, e)}}
                        />
                        <i className='mr7' />
                        {!data.etc ?
                            <p>{data.content}</p> : <>기타<br/></>
                        }
                    </label>
                    {!data.etc ?
                        null : <textarea
                            rows={1}
                            disabled={!data.isChecked}
                            ref={singleEtc}
                            data-index={qIndex}
                            value={data.content}
                            placeholder='답변을 입력해주세요'
                            style={{height: '41px'}}
                            onChange={(e) => {
                                setEtcAnswer(qIndex, e);
                                handleResizeHeight1();
                            }}/>
                    }
                </div>
            )
        }) : null;

    // 객관식 멀티 선택 리스트
    const singleMultiList = answerData[index]?.surveyType === SurveyType.singleMulti ?
        (answerData[index]?.surveyQuestion ?? []).map((data: IAnswer, qIndex: number) => {
            return (
                <div key={qIndex} className={data.etc ? 'q-box q-etc' : 'q-box'}>
                    <label className={!data.etc ? 'checkbox flexInline' : 'checkbox dpBK etc'} htmlFor={'checkbox_' + index + '_' + qIndex}>
                        <input type="checkbox"
                               id={'checkbox_' + index + '_' + qIndex}
                               name={'checkbox_' + index}
                               checked={!!data.isChecked}
                               onChange={(e) => {setSingleMultiAnswer(qIndex, e)}}
                        />
                        <svg className='checkOff mr7' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="그룹_85" data-name="그룹 85" transform="translate(-16 -153)">
                                <g id="타원_18" data-name="타원 18" transform="translate(16 153)" fill="#fff" stroke="#c1c1c1" strokeWidth="1">
                                    <circle cx="12" cy="12" r="12" stroke="none"/>
                                    <circle cx="12" cy="12" r="11.5" fill="none"/>
                                </g>
                                <path id="패스_42" data-name="패스 42" d="M3098.829,161.529l4.6,4.6,8.377-8.378" transform="translate(-3076.771 3.055)" fill="none" stroke="#c1c1c1" strokeWidth="2"/>
                            </g>
                        </svg>

                        <svg className='checkOn mr7' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g id="그룹_85" data-name="그룹 85" transform="translate(-16 -153)">
                                <circle id="타원_18" data-name="타원 18" cx="12" cy="12" r="12" transform="translate(16 153)" fill="#389df3"/>
                                <path id="패스_42" data-name="패스 42" d="M3098.829,161.529l4.6,4.6,8.377-8.378" transform="translate(-3076.771 3.055)" fill="none" stroke="#fff" strokeWidth="2"/>
                            </g>
                        </svg>
                        {!data.etc ?
                            <p>{data.content}</p> : <>기타<br/></>
                        }
                    </label>
                    {!data.etc ? null :
                        <textarea
                            rows={1}
                            disabled={!data.isChecked}
                            data-index={qIndex}
                            value={data.content}
                            ref={multiEtc}
                            placeholder='답변을 입력해 주세요.'
                            onChange={(e) => {
                                setEtcAnswer(qIndex, e);
                                handleResizeHeight2();
                            }}></textarea>
                    }
                </div>
            )
        }) : null;

    const brFilter = (data: string | undefined) => {
        if (!data) {
            return;
        }
        const titleF =  data.replace(/\n/g, '</br>');
        return (<span className={'title-span'} dangerouslySetInnerHTML={{__html: titleF}} />)
    }

    const handleResizeHeight1 = () => {
        if (!singleEtc.current) return;
        singleEtc.current.style.height = 'auto';
        singleEtc.current.style.height = singleEtc.current.scrollHeight + 'px';
    }
    const handleResizeHeight2 = () => {
        if (!multiEtc.current) return;
        multiEtc.current.style.height = 'auto';
        multiEtc.current.style.height = multiEtc.current.scrollHeight + 'px';
    }

    const handleResizeHeight3 = () => {
        if (!textareaRef.current) return;
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = textareaRef.current.scrollHeight + 2 + 'px';
    }

    useEffect(() => {
        handleResizeHeight1();
        handleResizeHeight2();
        handleResizeHeight3();
    }, [singleEtc, multiEtc, textareaRef])

    return (
        <div className='survey'>
            <h3 className={!answerData[index]?.description ? 'title mb15' : 'title'}>
                <span className={answerData[index]?.isEssential ? 'label red' : 'label blue'}>
                    {index + 1}.<i className={'ml3'}></i> {answerData[index]?.isEssential ? '필수' : '선택'}
                </span>
                <br/>
                {brFilter(answerData[index]?.title)}
            </h3>


            {answerData[index]?.description && <p className={'description mb15'}>{brFilter(answerData[index]?.description)}</p>}


            <div className={'survey-inner'}>
                {/*객관식 단일*/}
                {answerData[index]?.surveyType === SurveyType.singleSingle ? <>
                    {singleSingleList}
                </> : null}

                {/*객관식 멀티*/}
                {answerData[index]?.surveyType === SurveyType.singleMulti ? <>
                    {singleMultiList}
                </> : null}

                {/*짧은 주관식*/}
                {answerData[index]?.surveyType === SurveyType.shortAnswer ? <>
                    <input type="text"
                           style={{width: '100%'}}
                           placeholder='답변을 입력하세요.'
                           ref={inputRef}
                           value={answerData[index]?.answer || ''}
                           onChange={(e) => {setTextAnswer(e)}}
                    />
                </> : null}

                {/*장문 주관식*/}
                {answerData[index]?.surveyType === SurveyType.essayAnswer ? <>
				<textarea rows={1}
                          ref={textareaRef}
                          placeholder='답변을 입력하세요.'
                          style={{width: '100%', resize: 'none'}}
                          value={answerData[index]?.answer || ''}
                          onChange={(e) => {
                              setTextAnswer(e);
                              handleResizeHeight3();
                          }
                }
                />
                </> : null}

                {/*날짜*/}
                {answerData[index]?.surveyType === SurveyType.dateAnswer ? <>
                    <Datepicker dateType={answerData[index]?.datepickerType || ''}
                                answer={answerData[index]?.answer ? answerData[index]?.answer : ''}
                                index={index}/>
                </> : null}
            </div>
        </div>
    )
}

export default React.memo(Survey);
