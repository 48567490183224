import React, { useCallback, useEffect, useRef, useState} from 'react';
import './datepicker.scss';
import {IDatepickerOptionProps, IDatepickerProps, ISurveyAnswer} from '../../app.constant';
import {useRecoilState} from 'recoil';
import {answerAtom} from '../../atoms/atoms';
declare const moment: any;

const Datepicker = ({dateType, answer, index}: IDatepickerProps) => {
    const [answerData, setAnswerData] = useRecoilState(answerAtom);
    const [hour, setHour] = useState('defaultValue');
    const [minute, setMinute] = useState('defaultValue');
    const inputRef = useRef<HTMLInputElement>(null);
    const hourRef = useRef<HTMLSelectElement>(null);
    const minuteRef = useRef<HTMLSelectElement>(null);

    const setDateAnswer = useCallback((e: number) => {
        setAnswerData((prev: ISurveyAnswer[]) =>
            prev.map((item:ISurveyAnswer, idx: number): ISurveyAnswer =>
                index === idx ? {...item, answer: e} : item)
        )
    }, []);

    /** 날짜 세팅해주기 */
    const setDatePicker = () => {

        if (answer) {
            switch (dateType) {
                case 'dateOnly':
                    if (inputRef.current) {
                        inputRef.current.value = moment(answer).format('YYYY-MM-DD');
                    }
                    break;
                case 'timeOnly':
                case 'dateTime':
                    if (inputRef.current) {
                        inputRef.current.value = moment(answer).format('YYYY-MM-DD');
                    }
                    if (hourRef.current && minuteRef.current) {
                        setMinute(moment(answer).format('mm'));
                        setHour(moment(answer).format('HH'));
                    }
                    break;
            }
        } else {
            if (inputRef.current) {
                inputRef.current.value = '날짜 선택';
            }
        }
    }

    let datepickerData: number;
    /** datepicker 시작 */
    const datepickerStart = useCallback((): void => {
        const datepickerEl = $((inputRef as any).current) as any;
        datepickerEl.datetimepicker(setOptions());
        setDatePicker();
        datepickerEl.on('dp.change dp.hide', (e: any) => {
            const newD = new Date();
            setDateAnswer(new Date(e.date ?? newD).getTime());
            datepickerData = new Date(e.date ?? newD).getTime();
            datepickerEl.val(moment(e.date ?? newD).format('YYYY-MM-DD'));
            console.log(new Date(e.date ?? newD).getTime());
        })
    }, [dateType, answer]);

    const handleHour = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {target: {value}} = event;
        const date = datepickerData ? datepickerData : answer ? answer : new Date();
        const nDate = new Date(date).setHours(parseInt(value), (minuteRef as any).current.value);
        setDateAnswer(new Date(nDate).getTime());
        console.log('handleHour', new Date(nDate).getTime());
        setHour(value);
    }

    const handleMinute = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const {target: {value}} = event;
        const date = datepickerData ? datepickerData : answer ? answer : new Date();
        const nDate = new Date(date).setHours((hourRef as any).current.value, parseInt(value));
        setDateAnswer(new Date(nDate).getTime());
        console.log('handleMinute', new Date(nDate).getTime());
        setMinute(value);
    }

    // 랜더링 될 때 실행
    useEffect(() => {
        datepickerStart();
        return () => {
            if (!inputRef.current) return;
            const el = ($(inputRef.current) as any);
            if (el && el.datepicker) el.datepicker('disable');
        }
    }, []);

    const hoursArr = [
        '00', '01', '02', '03', '04', '05', '06', '07', '08', '09',
        '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
    ];
    const minuetsArr = [
        '00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'
    ];

    /** datepicker 옵션 */
    const setOptions = (customOption?: IDatepickerOptionProps): any => {
        let option: IDatepickerOptionProps = {
            dayViewHeaderFormat: 'YYYY MMMM', // 해더 기본 포맷
            format: 'YYYY-MM-DD', // 기본 포맷
            defaultDate: new Date(), // 기본 시간
            sideBySide: false, // 시계를 같이 표시할것인가
            locale: 'ko', // 언어
            icons: { // 아이콘 커스텀
                next: 'date-picker-next date-picker-icon',
                previous: 'date-picker-prev date-picker-icon',
                up: 'date-picker-up date-picker-icon',
                down: 'date-picker-down date-picker-icon',
            },
        };

        return customOption ? {...option, ...customOption} : option;
    }

    return (
        <div className={'q-date-box'}>
            {dateType === 'dateOnly' && <div className='relative'>
                <input
                    inputMode={'none'}
                    ref={inputRef}
                    className="datepicker-el"
                    type="text"/>
            </div>}

            {dateType === 'timeOnly' && <>
                <div className='relative'>
                    <select value={hour} onChange={handleHour} ref={hourRef}>
                        {/*<option value={'defaultValue'} disabled={true}>시간</option>*/}
                        {hoursArr.map(h=> <option value={h} key={h + new Date().getTime()}>{h}</option>)}
                    </select>
                    시
                </div>
                <div className='relative'>
                    <select value={minute} onChange={handleMinute} ref={minuteRef}>
                        {/*<option value={'defaultValue'} disabled={true}>분</option>*/}
                        {minuetsArr.map(m => <option value={m} key={m + new Date().getTime()}>{m}</option>)}
                    </select>
                    분
                </div>
            </>}

            {dateType === 'dateTime' && <>
                <div className='relative'>
                    <input
                        inputMode={'none'}
                        ref={inputRef}
                        className="datepicker-el"
                        type="text"/>
                </div>
                <div className='relative'>
                    <select value={hour} onChange={handleHour} ref={hourRef}>
                        {/*<option value={'defaultValue'} disabled={true}>시간</option>*/}
                        {hoursArr.map(h=> <option value={h} key={h + new Date().getTime()}>{h}</option>)}
                    </select>
                    시
                </div>
                <div className='relative'>
                    <select value={minute} onChange={handleMinute} ref={minuteRef}>
                        {/*<option value={'defaultValue'} disabled={true}>분</option>*/}
                        {minuetsArr.map(m => <option value={m} key={m + new Date().getTime()}>{m}</option>)}
                    </select>
                    분
                </div>
            </>}
        </div>

    )
}

export default React.memo(Datepicker);
