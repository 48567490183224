import React, {useEffect, useState} from 'react';
import './content.scss';
import 'react-circular-progressbar/dist/styles.css';
import ContentBtn from './contentBtn';
import {TransformWrapper, TransformComponent} from 'react-zoom-pan-pinch';

type contentProps = {
    writer: string;
    receiver: string;
    content: any;
    term: string;
    files: any;
    otherFiles: any;
}

const Content: React.FC<contentProps> = (props) => {

    const [files, setFiles] = useState([] as any);
    const [visible, setVisible] = useState(false);
    const [imgSrc, setImgSrc] =useState({src: '', alt: ''});

    // 파일 데이터 변화 있을 때마다 발생
    useEffect(() => {
        setFiles(props.files);
    }, [props.files, props.otherFiles])

    // 한번만 발생
    useEffect(() => {
        onClickImage();
    }, [])

    // 본문안의 이미지 클릭시 발생
    const onClickImage = () => {
        $('.survey-content').on('click', (event) => {
            if (!(event.target as any).currentSrc) {
                return;
            }
            setImgSrc(
                {
                    src: (event.target as any).currentSrc,
                    alt: 'img',
                })
            setVisible(true);
        })
    }

    // 팝업 닫기 (이미지 줌 팝업)
    const closePop = (event: any) => {
        if (event.target.className !== 'pop') {
            return;
        }
        setVisible(false);
    }

    const fileList = (files && files.length > 0) ? files.map((data: any, index: number) => {
        return (
            <ContentBtn data={data} index={index} key={index} isPdf={data.pdf} />
        )
    }) : null;

    return (
        <>
            {/*설문 컨텐츠*/}
            <div className="survey-content">
                {
                    (props.writer && props.receiver) ?
                        <div className='user-info mt15 mb20'>
                            <p className="writer mb3">보낸 사람 : {props.writer}</p>
                            <p className="receiver">받는 사람 : {props.receiver}</p>
                        </div>
                        : null
                }
                {!props.writer || !props.receiver ? <br/> : null}
                <div className={'content'} dangerouslySetInnerHTML={{__html: props.content}}/>

                {props.term ? <p className="term mt20 plN">설문기간 : {props.term}</p>:null}
                {files && files.length > 0 ?
                    <div className="mt20">
                        {fileList}
                    </div>
                    : null
                }
            </div>

            {/*이미지 줌 팝업*/}
            {visible &&
            <div className='pop' onClick={($event) => closePop($event)}>
                <span className='pop-close' onClick={() => {setVisible(!visible)}}> </span>
                <TransformWrapper>
                    <TransformComponent>
                        <img src={imgSrc.src} alt="test" />
                    </TransformComponent>
                </TransformWrapper>
            </div>
            }
        </>
    )
}

export default Content;
