import React, {useState} from 'react';
import ProgressBar from '../progreesBar/progressBar';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';

const ContentBtn: React.FC<any> = (props) => {
    const [isFileClick, setIsFileClick] = useState(false);

    /*const waitSecond = (second: number) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve('success');
            }, 2000);
        })
    }*/

    const fileClick = async (data: any, index: any): Promise<any> => {
        const win = window as any;
        if (win.ReactNativeWebView) {
            win.ReactNativeWebView.postMessage(
                JSON.stringify({
                    type: 'REQ_ALIMI_FILE_VIEW',
                    data: data
                })
            );
        }
        setIsFileClick(true);
        setTimeout(() => {
            setIsFileClick(false);
        }, 1600)
    }

    return (
        <>
            <div className="file cP" onClick={() => fileClick(props.data, props.index)}>
                <img src="//update.coolmessenger.com/_ImageServer/alimi/survey/icon_file_20x20.png" alt="img"/>
                <span>{props.data.originFileName || props.data.uploadFileName}</span>
                {
                    !isFileClick ?
                        <></>
                        :
                        <div style={{ width: 30, height: 30 }} className='flex'>
                            <ProgressBar values={[0, 20, 40, 60, 80, 100]}>
                                {(percentage: any) => (
                                    <CircularProgressbar
                                        value={percentage}
                                        strokeWidth={20}
                                        styles={buildStyles({
                                            pathTransition:
                                                percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                        })}/>
                                )}
                            </ProgressBar>
                        </div>
                }
            </div>

            {props.isPdf ?
                <div className="file cP" onClick={() => fileClick(props.isPdf, props.index)}>
                    <img src="//update.coolmessenger.com/_ImageServer/alimi/survey/icon_file_20x20.png" alt="img"/>
                    <span>{props.isPdf.originFileName || props.isPdf.uploadFileName}</span>
                    {
                        !isFileClick ?
                            <></>
                            :
                            <div style={{ width: 30, height: 30 }} className='flex'>
                                <ProgressBar values={[0, 20, 40, 60, 80, 100]}>
                                    {(percentage: any) => (
                                        <CircularProgressbar
                                            value={percentage}
                                            strokeWidth={20}
                                            styles={buildStyles({
                                                pathTransition:
                                                    percentage === 0 ? "none" : "stroke-dashoffset 0.5s ease 0s"
                                            })}/>
                                    )}
                                </ProgressBar>
                            </div>
                    }
                </div> : null
            }

        </>
    )
}

export default ContentBtn;
