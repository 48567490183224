import React, {useCallback, useEffect, useState} from 'react';
import './result.scss';
import Header from '../header/header';
import axios from 'axios';
import {IHeaderProps, SEARCH_BBS_URL} from '../../app.constant';
import {useLocation} from 'react-router-dom';
import { getQueryString, getSurveyDate} from '../../service/common';
declare const moment: any;

const Result: React.FC<any> = () => {
    const location = useLocation();
    const [headerData, setHeaderData] = useState<IHeaderProps>();

    const getSurveyData = useCallback(async (): Promise<any> => {
        const streamId = location.pathname.split('/').pop() as string;
        const response =
            await axios.get(`${SEARCH_BBS_URL}`.replace(':streamId', streamId) + `?${getQueryString()}`);
        const surveyData = response.data.data;
        const {startDate, endDate, startDateHour, startDateMinute, endDateHour, endDateMinute, } =
            getSurveyDate([surveyData.surveyStartDate.toString(), surveyData.surveyEndDate.toString()]);

        setHeaderData({
            type: surveyData.streamType,
            title: surveyData.title,
            date: surveyData.writeDate,
            modifyDate: surveyData.modifyDate,
            receiver: `${surveyData.stSchoolName} ${surveyData.stParentClsName} ${surveyData.stClsName} ${surveyData.stName}`,
            surveyStartDate: `${moment(startDate.substr(0, 8)).format(`YYYY.MM.DD ${startDateHour}:${startDateMinute}`)}`,
            surveyEndDate: `${moment(endDate.substr(0, 8)).format(`YYYY.MM.DD ${endDateHour}:${endDateMinute}`)}`,
            isResponse: surveyData.isResponse,
        })
    }, [location.pathname])

    useEffect(() => {
        getSurveyData().then();
    }, []);

    // 알리미 목록으로 가기 버튼 클릭시 호출
    const goList = () => {
        const win = window as any;
        if (win.ReactNativeWebView) {
            win.ReactNativeWebView.postMessage(
                JSON.stringify({ type: 'REQ_ALIMI_LIST' })
            );
        }
    }

    return (
        <>
        <Header {...headerData} />
        <div className='pl15 pr15 result'>
            <p style={{textAlign: 'center'}}>
                {headerData?.receiver ? headerData.receiver : ''}<br/>
                {moment(headerData?.writeDate).format('YYYY년 MM월 DD일 A hh시 mm분')}<br/>
                설문/투표를 완료하였습니다.
            </p>
            <span className='send-btn mt40' onClick={goList}>알리미 목록으로 가기</span>
        </div>
        </>
    )
}

export default Result
