import React, {useEffect} from 'react';
import './App.scss';
import SurveyView from './components/surveyView/surveyView';
import {useRoutes} from 'react-router-dom';
import Result from './components/result/result';
import NoticeView from './components/noticeView/noticeView';

const App: React.FC = () => {
    useEffect(() => {
        document.title = "쿨알리미"
    }, [])

    return useRoutes([
        {path: '/classBoard/:id', element: <NoticeView/>}, // 반 게시판
        {path: '/survey/:id', element: <SurveyView/>}, // 설문 응답
        {path: '/stream/:id', element: <SurveyView/>}, // 설문 응답
        {path: '/result/:id', element: <Result/>}, // 설문 결과
        {path: '/notice/:id', element: <NoticeView/>}, // 공지/안내
        {path: '/formattingLibrary/:id', element: <NoticeView/>}, // 서식
        {path: '/schoolBoard/:id', element: <NoticeView/>}, // 학교 홈페이지 게시물
        {path: '/mealDiet/:id', element: <NoticeView/>} // 식단
    ])

    /*return (
        <>
            <Routes>
                <Route path="/" element={<SurveyView/>}/>
                <Route path="/result" element={<Result/>}/>
            </Routes>
        </>
    );*/
}

export default App;
