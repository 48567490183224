import {
    ALIMI_INVITATION_URL,
    COOL_FILE_UPLOAD_BINARY_URL_BY_COOLIDX,
    COOL_FILE_URL,
    ISignatureParams,
    ISignatureProps,
    ISurveyAnswer,
    ISurveyDate,
    ISurveyDateInfos,
    IUserInfoProps,
    MEMBER_SIGN_SAVE_URL_API,
    SEARCH_MEMBER_BY_ACCESS_TOKEN,
    SurveyType,
    TOKEN_NAME
} from '../app.constant';
import axios from 'axios';

/** 토큰 가져오기 (쿠키에서) */
export const getTokenFromCookie = (): string => {
    const token = getCookie(TOKEN_NAME);
    if (!token) {
        return '';
    }
    return token;
}

/** 토큰 가져오기 (로컬 스토리지에서) */
export const getTokenFromStorage = (): any => {
    return localStorage.getItem(TOKEN_NAME);
}

/** 토큰 가져오기 */
export const getToken = (): any => {
    return getTokenFromStorage() || getTokenFromCookie();
}

/** url 에서 parameter 추출 */
export const getParam = (sName: any): string => {
    let params = window.location.search.slice(window.location.search.indexOf('?') + 1) as any;
    let sVal = '';
    params = params.split('&');
    for (let i = 0; i < params.length; i++) {
        let temp = params[i].split('=');
        if (temp[0] === sName) {
            sVal = temp[1];
        }
    }
    return sVal;
}

/** 쿠키 값 가져오기 */
export const getCookie = (name: string) => {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

/** 사용자 정보 가져오기 */
export const getUserDataForToken = (token: string): Promise<any> => {
    return axios
    .get(SEARCH_MEMBER_BY_ACCESS_TOKEN.replace(':accessToken', token));
}

/** 쿼리 스트링 가져오기 */
export const getQueryString = (): string => {
    const searchParams = new URLSearchParams();
    getParam('msgId') && (searchParams.append('msgId', getParam('msgId')));
    getParam('contactId') && (searchParams.append('contactId', getParam('contactId')));
    getParam('metaId') && (searchParams.append('metaId', getParam('metaId')));
    searchParams.append('isReceiverOpt', 'true');
    return searchParams.toString();

    // const qsObj: ISurveySearchProps | string = {}
    // getParam('msgId') && (qsObj.msgId = getParam('msgId'));
    // getParam('contactId') && (qsObj.contactId = getParam('contactId'));
    // getParam('metaId') && (qsObj.metaId = getParam('metaId'));
    // qsObj.isReceiverOpt = true;
    // return querystring.stringify(qsObj);
}

/** 설문 날짜 가져오기 */
export const getSurveyDate = ([surveyStartDate, surveyEndDate]: ISurveyDate[]): ISurveyDateInfos => {
    const startDate = surveyStartDate.toString();
    const endDate = surveyEndDate.toString();
    const startDateHour = startDate.slice(8, 10) ? startDate.slice(8, 10) : '00';
    const startDateMinute = startDate.slice(10, 12) ? startDate.slice(10, 12) : '00';
    const endDateHour = endDate.slice(8, 10) ? endDate.slice(8, 10) : '24';
    const endDateMinute = endDate.slice(10, 12) ? endDate.slice(10, 12) : '00';
    return {startDate, endDate, startDateHour, startDateMinute, endDateHour, endDateMinute};
}

/** 설문 validation */
export const  validation = (answerData: ISurveyAnswer[]) => {
    let isOk = true;
    for (let d of answerData) {
        // 필수 답변이 아니면 통과
        if (!d.isEssential) {
            isOk = true;
        } else {
            // 필수 답변이면 필터
            switch (d.surveyType) {
                case SurveyType.singleSingle:
                    if (!validationSingleQ(d, 'single')) {
                        return false;
                    }
                    break;
                case SurveyType.singleMulti:
                    if (!validationSingleQ(d, 'multi')) {
                        return false;
                    }
                    break;
                case SurveyType.shortAnswer:
                    if (!d.answer || d.answer.toString().trim() === '') {
                        return false;
                    }
                    break;
                case SurveyType.essayAnswer:
                    if (!d.answer || d.answer.toString().trim() === '') {
                        return false;
                    }
                    break;
                case SurveyType.dateAnswer:
                    if (!d.answer) {
                        return false;
                    }
                    break;
            }
        }
    }
    return isOk;
}

/** 객관식 질문 validation */
export const validationSingleQ = (data: any, type: string): boolean => {
    let isOk = false;
    for (let d of data.surveyQuestion) {
        if (d.isChecked && !d.etc) {
            isOk = true;
        }
        if (type === 'single' && d.isChecked && d.etc && d.content.trim()) {
            isOk = true;
        }
        if (type === 'multi' && d.isChecked && d.etc && d.content.trim()) {
            isOk = true;
        }
        if (type === 'multi' && d.isChecked && d.etc && !d.content.trim()) {
            isOk = false;
        }
    }
    return isOk;
}

/** 유저 데이터 체크로 페이지 이동 여부 체크 */
export const setUserAuthenticated = (userInfo: IUserInfoProps): void => {
    const token = getToken();
    if (!token || !userInfo || !userInfo?.data?.result || !userInfo?.data?.data) {
        window.location.href = ALIMI_INVITATION_URL;
        return;
    }
}

/** 유저정보 체크해서 없으면 앱 설치 url 로 이동 */
export const checkUserInfo = (userInfo: IUserInfoProps) => {
    setUserAuthenticated(userInfo);
}

/** 서명 쿨파일에 업로드 > db 저장 */
export const signUpload = async(isSign = false, signatureInfo: ISignatureProps, userInfo: IUserInfoProps, signatureParams?: ISignatureParams): Promise<any> => {
    // 서명하기 첨부에 체크하고, 첨부한 파일이 있을 경우 쿨파일에 업로드
    if (signatureInfo.isSignatureCheck && isSign) {
        const signUploadResult = await axios
        .post(`${COOL_FILE_UPLOAD_BINARY_URL_BY_COOLIDX}`
        .replace(':coolIdx', userInfo?.data?.data.idx), signatureParams);
        if (signUploadResult && signUploadResult.data &&
            signUploadResult.data.result && signUploadResult.data.data.length > 0) {
            const signData = signUploadResult.data.data[0];

            const token = getToken();
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const formData = new FormData();
            formData.append('imageURL', `${COOL_FILE_URL}/api/download/${signData.pathToken}?fileName=${signData.originFileName}`);
            formData.append('target', 'sign');
            await axios.post(MEMBER_SIGN_SAVE_URL_API, formData);
        }
    }
}

/** 스크립트 로드 */
export const loadScript = (src: string) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.onload = resolve; // 스크립트 로딩 완료 시 resolve 호출
        script.onerror = reject; // 스크립트 로딩 에러 시 reject 호출
        document.body.appendChild(script);
    });
}

/** 스타일 로드 */
export const loadStyle = (href: string) => {
    return new Promise((resolve, reject) => {
        const style = document.createElement('link');
        style.href = href;
        style.rel = 'stylesheet';
        style.onload = resolve; // 스크립트 로딩 완료 시 resolve 호출
        style.onerror = reject; // 스크립트 로딩 에러 시 reject 호출
        document.body.appendChild(style);
    })
}
