import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
if (container) {
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(
        <BrowserRouter>
            <React.StrictMode>
                <RecoilRoot>
                    <App/>
                </RecoilRoot>
            </React.StrictMode>
        </BrowserRouter>,
    );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
