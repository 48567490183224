declare const $: any;
import {
    COOL_LOGIN_CALLBACK_FOR_EMOTICON_URL,
    COOL_LOGIN_CALLBACK_FOR_SAMSTORY_URL,
    COOL_LOGIN_CALLBACK_URL,
    COOL_LOGIN_CHECK_URL,
    COOL_LOGIN_URL,
    COOLSCHOOL_CALLBACK_URL,
    DEV_COOL_LOGIN_CHECK_URL,
    DEV_COOL_LOGIN_URL,
    DEV_COOLSCHOOL_CALLBACK_URL,
    DEV_SEARCH_MEMBER_BY_ACCESS_TOKEN,
    Env,
    FN_LOGOUT_PROC_URL,
    FN_LOGOUT_URL,
    LOCAL_COOL_LOGIN_CHECK_URL,
    LOCAL_COOL_LOGIN_URL,
    LOCAL_COOLSCHOOL_CALLBACK_URL,
    LOCAL_SEARCH_MEMBER_BY_ACCESS_TOKEN,
    LogoutOpts,
    SEARCH_MEMBER_BY_ACCESS_TOKEN,
    ServerName,
} from './constants'
import {EnvHelper} from "./env-helper";
import {StorageHelper} from "./storage-helper";
import {UrlHelper} from './url-helper'

export class Login {
    private urlHelper: UrlHelper = new UrlHelper();
    private storageHelper: StorageHelper = new StorageHelper();
    private envHelper: EnvHelper = new EnvHelper();

    /**
     * 쿨스쿨 로그인 페이지로 이동
     * @param {string} clientId
     * @param {string} action (선택)
     * @param isDev 개발 URL 사용여부
     */
    goDefaultCoolLogin(clientId = '', action = '', isDev: boolean | 'prod' | 'dev' | 'local' = false) {
        const param = {
            client_id: clientId,
            redirect_uri: COOL_LOGIN_CALLBACK_URL,
            redirect_uri_next: window.location.href
        } as any;
        if (action) {
            param.do_action = action;
        }
        let loginURL = COOL_LOGIN_URL;
        if (typeof isDev === 'boolean') {
            if (isDev) {
                loginURL = DEV_COOL_LOGIN_URL;
            }
        } else {
            switch (isDev) {
                case 'prod': {
                    loginURL = COOL_LOGIN_URL;
                    break;
                }
                case 'dev': {
                    loginURL = DEV_COOL_LOGIN_URL;
                    break;
                }
                case 'local': {
                    loginURL = LOCAL_COOL_LOGIN_URL;
                    break;
                }
                default: {
                    loginURL = COOL_LOGIN_URL;
                    break;
                }
            }
        }


        let paramStr = $.param(param);
        if (param.redirect_uri_next.search('&') === -1 && action !== '') {
            paramStr = paramStr.replace('&do_action=', '?do_action=');
        }
        window.location.href = `${loginURL}?${paramStr}`;
    }

    /**
     * 샘스토리용 로그인 페이지로 이동
     * @param clientId
     * @param action
     * @param isDev
     */
    goCoolLoginForSamstory(clientId = '', action: any = '', isDev = false) {
        const param = {
            client_id: clientId,
            redirect_uri: COOL_LOGIN_CALLBACK_FOR_SAMSTORY_URL,
            redirect_uri_next: window.location.href
        } as any;
        if (action) {
            param.do_action = action;
        }
        let loginURL = COOL_LOGIN_URL;
        if (isDev) {
            loginURL = DEV_COOL_LOGIN_URL;
        }
        let paramStr = $.param(param);
        if (param.redirect_uri_next.search('&') === -1 && action) {
            paramStr = paramStr.replace('&do_action=', '?do_action=');
        }
        window.location.href = `${loginURL}?${paramStr}`;
    }

    /**
     * 샘스토리용 next url 지정하여 로그인 페이지 이동
     * @param clientId
     * @param action
     * @param nextUrl
     * @param isDev
     */
    goCoolLoginNextUrlForSamstory(clientId = '', action = '', nextUrl: string, isDev = false) {
        const param = {
            client_id: clientId,
            redirect_uri: COOL_LOGIN_CALLBACK_FOR_SAMSTORY_URL,
            redirect_uri_next: nextUrl,
            do_action: action,
        };
        let loginURL = COOL_LOGIN_URL;
        if (isDev) {
            loginURL = DEV_COOL_LOGIN_URL;
        }
        let paramStr = $.param(param);
        if (param.redirect_uri_next.search('&') === -1 && action !== '') {
            paramStr = paramStr.replace('&do_action=', '?do_action=');
        }
        window.location.href = `${loginURL}?${paramStr}`;
    }

    /**
     * 이모티콘용 로그인 페이지로 이동
     * @param clientId
     * @param action
     * @param isDev
     */
    goCoolLoginForEmoticon(clientId = '', action = '', isDev = false) {
        const param = {
            client_id: clientId,
            redirect_uri: COOL_LOGIN_CALLBACK_FOR_EMOTICON_URL,
            redirect_uri_next: window.location.href,
            do_action: action,
        };
        let loginURL = COOL_LOGIN_URL;
        if (isDev) {
            loginURL = DEV_COOL_LOGIN_URL;
        }
        let paramStr = $.param(param);
        if (param.redirect_uri_next.search('&') === -1 && action !== '') {
            paramStr = paramStr.replace('&do_action=', '?do_action=');
        }
        window.location.href = `${loginURL}?${paramStr}`;
    }

    /**
     * 이모티콘용 next url 지정하여 로그인 페이지 이동
     * @param clientId
     * @param action
     * @param nextUrl
     * @param isDev
     */
    goCoolLoginNextUrlForEmoticon(clientId = '', action = '', nextUrl: string, isDev = false) {
        const param = {
            client_id: clientId,
            redirect_uri: COOL_LOGIN_CALLBACK_FOR_EMOTICON_URL,
            redirect_uri_next: nextUrl,
            do_action: action,
        };
        let loginURL = COOL_LOGIN_URL;
        if (isDev) {
            loginURL = DEV_COOL_LOGIN_URL;
        }
        let paramStr = $.param(param);
        if (param.redirect_uri_next.search('&') === -1 && action !== '') {
            paramStr = paramStr.replace('&do_action=', '?do_action=');
        }
        window.location.href = `${loginURL}?${paramStr}`;
    }

    /**
     * 프로필용 로그인 페이지로 이동
     * @param clientId
     * @param action
     * @param isDev
     */
    goCoolLoginForProfile(clientId = '', action = '', isDev: boolean | 'prod' | 'dev' | 'local' = false) {
        let loginURL = COOL_LOGIN_URL;
        let callbackURL = COOLSCHOOL_CALLBACK_URL;
        if (typeof isDev === 'boolean') {
            if (isDev) {
                loginURL = DEV_COOL_LOGIN_URL;
                callbackURL = DEV_COOLSCHOOL_CALLBACK_URL;
            }
        } else {
            switch (isDev) {
                case 'prod': {
                    loginURL = COOL_LOGIN_URL;
                    callbackURL = COOLSCHOOL_CALLBACK_URL;
                    break;
                }
                case 'dev': {
                    loginURL = DEV_COOL_LOGIN_URL;
                    callbackURL = DEV_COOLSCHOOL_CALLBACK_URL;
                    break;
                }
                case 'local': {
                    loginURL = LOCAL_COOL_LOGIN_URL;
                    callbackURL = LOCAL_COOLSCHOOL_CALLBACK_URL;
                    break;
                }
                default: {
                    loginURL = COOL_LOGIN_URL;
                    callbackURL = COOLSCHOOL_CALLBACK_URL;
                    break;
                }
            }
        }

        const param = {
            client_id: clientId,
            redirect_uri: callbackURL,
            redirect_uri_next: window.location.href,
            do_action: action,
        };

        const paramStr = $.param(param);
        window.location.href = `${loginURL}?${paramStr}`;
    }

    /**
     * 쿨스쿨 로그인 이후 redirect 처리
     */
    coolLoginCallbackRedirect() {
        let redirectNext = this.urlHelper.getQueryParam('redirect_uri_next');
        const redirect = this.urlHelper.getQueryParam('redirect');
        if (redirect) {
            redirectNext = redirect;
        }
        const doAction = this.urlHelper.getQueryParam('do_action');
        if (redirectNext) {
            if (doAction) {
                if (redirectNext.indexOf('?') !== -1) {
                    location.href = redirectNext + '&do_action=' + doAction;
                } else {
                    location.href = redirectNext + '?do_action=' + doAction;
                }
            } else {
                location.href = redirectNext;
            }
        } else {
            location.href = '/';
        }
    }

    /**
     * 통합 계정 로그인 세션이 있는지 검사
     */
    coolLoginCheck(isDev: boolean | 'prod' | 'dev' | 'local' = false): any {

        let loginCheckURL = COOL_LOGIN_CHECK_URL;

        if (typeof isDev === 'boolean') {
            if (isDev) {
                loginCheckURL = DEV_COOL_LOGIN_CHECK_URL;
            }
        } else {
            switch (isDev) {
                case 'prod': {
                    loginCheckURL = COOL_LOGIN_CHECK_URL;
                    break;
                }
                case 'dev': {
                    loginCheckURL = DEV_COOL_LOGIN_CHECK_URL;
                    break;
                }
                case 'local': {
                    loginCheckURL = LOCAL_COOL_LOGIN_CHECK_URL;
                    break;
                }
                default: {
                    loginCheckURL = COOL_LOGIN_CHECK_URL;
                    break;
                }
            }
        }

        return $.ajax({
            method: 'GET',
            dataType: 'jsonp',
            url: loginCheckURL
        });
    }

    async coolLoginCheckByAccessToken(isDev: 'prod' | 'dev' | 'local' = 'prod', authorization: string): Promise<any> {
        let loginCheckURL = SEARCH_MEMBER_BY_ACCESS_TOKEN;
        switch (isDev) {
            case 'prod': {
                loginCheckURL = SEARCH_MEMBER_BY_ACCESS_TOKEN;
                break;
            }
            case 'dev': {
                loginCheckURL = DEV_SEARCH_MEMBER_BY_ACCESS_TOKEN;
                break;
            }
            case 'local': {
                loginCheckURL = LOCAL_SEARCH_MEMBER_BY_ACCESS_TOKEN;
                break;
            }
            default: {
                loginCheckURL = SEARCH_MEMBER_BY_ACCESS_TOKEN;
                break;
            }
        }

        return $.ajax({
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + authorization
            },
            url: loginCheckURL
        });

    }

    /**
     * 통합 계정 로그인 세션이 있는지 검사
     */
    cooLoginCheck(isDev: boolean | 'prod' | 'dev' | 'local' = false): any {
        return this.coolLoginCheck(isDev);
    }


    /**
     * 로그아웃 함수
     * @param clientId
     * @param env
     * @param logoutOpts
     */
    async logout(clientId: string, env: Env, logoutOpts?: LogoutOpts | any): Promise<boolean> {

        const logoutUrl = this.envHelper.getUrl(env, ServerName.MEMBER, FN_LOGOUT_URL);
        const setting = {
            url: logoutUrl.replace(':client_id', clientId),
            type: 'GET',
            contentType: "application/json",
            dataType: "jsonp",
        };
        const settingResponse = await $.ajax(setting);
        if (settingResponse.result === 'success') {

            this.storageHelper.setCookie('accessToken', '', -1 as any);
            this.storageHelper.setCookie('client_id', '', -1 as any);

            logoutOpts = logoutOpts || {};
            if(logoutOpts.isLogoutProc === undefined || logoutOpts.isLogoutProc === true) {
                if (!logoutOpts.logoutProcUrl) {
                    await $.get(FN_LOGOUT_PROC_URL(`//${window.location.host}`));
                } else {
                    await $.get(FN_LOGOUT_PROC_URL(`${logoutOpts.logoutProcUrl}`));
                }
            }
            if (logoutOpts.isRestoreLocation === undefined || logoutOpts.isRestoreLocation === true) { // 로그아웃 후 있던 페이지에 머무를 것이냐 말것이냐
                location.href = `//${window.location.host}${window.location.pathname}`;
            }

            return true;
        }

        return false;
    }
}
