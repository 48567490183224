import {atom, selector} from 'recoil';
import {ISurveyAnswer, IUserInfoProps} from '../app.constant';

/** 응답 데이터 atom */
export const answerAtom = atom<ISurveyAnswer[]>({
    key: 'answer',
    default: []
})

export const answerSelector = selector({
    key: 'answerSelector',
    get: ({get}) => {
        const answer = get(answerAtom);
    }
})

/** 유저 정보 atom */
export const userInfoAtom = atom<IUserInfoProps>({
    key: 'userInfo',
    default: {}
})
