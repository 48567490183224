export class StorageHelper {

    /**
     * 쿠키 저장
     * @param cname
     * @param cvalue
     * @param exdays
     */
    setCookie(cname: string, cvalue: string, exdays: number) {
        const d = new Date();
        // tslint:disable-next-line
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    /**
     * 쿠키 저장 시간 기준
     * @param cname
     * @param cvalue
     * @param hour
     */
    setCookieHour(cname: string, cvalue: string, hour: number) {
        const d = new Date();
        // tslint:disable-next-line
        d.setTime(d.getTime() + (hour * 60 * 60 * 1000));
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
    }

    /**
     * 쿠키가져온기
     * @param cname
     */
    getCookie(cname: string) {
        const name = cname + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        // tslint:disable-next-line
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }

    /**
     * 세션 스토리지에 값 저장
     * @param sname  세션 스토리지에 저장할 이름
     * @param svalue 세션 스토리지에 저장할 값
     */
    setSessionStorage(sname: string, svalue: string){
        sessionStorage.setItem(sname, svalue);
    }

    /**
     * 세션 스토리지에서 값을 가져온다.
     * @param sname
     * @return string  이름에 해당하는 값을 반환
     */
    getSessionStorage(sname: string): any {
        return sessionStorage.getItem(sname);
    }

    /**
     * 원하는 아이템 삭제
     * @param sname
     */
    removeItemSessionStorage(sname: string) {
        sessionStorage.removeItem(sname);
    }


    /**
     * 모두 삭제
     */
    clearSessionStorage() {
        sessionStorage.clear();
    }

    /**
     * 로컬 스토리지에 값 저장
     * @param sname  로컬 스토리지에 저장할 이름
     * @param svalue 로컬 스토리지에 저장할 값
     */
    setLocalStorage(sname: string, svalue: string){
        localStorage.setItem(sname, svalue);
    }

    /**
     * 로컬 스토리지에서 값을 가져온다.
     * @param sname
     * @return string  이름에 해당하는 값을 반환
     */
    getLocalStorage(sname: string): any {
        return localStorage.getItem(sname);
    }

    /**
     * 원하는 아이템 삭제
     * @param sname
     */
    removeItemLocalStorage(sname: string) {
        localStorage.removeItem(sname);
    }


    /**
     * 모두 삭제
     */
    clearLocalStorage() {
        localStorage.clear();
    }
}
