import React, {useEffect, useState} from 'react';
import './header.scss';
import {IHeaderProps, StreamType} from '../../app.constant';

declare const moment: any;

const Header: React.FC<IHeaderProps> = (props: IHeaderProps) => {
    const [duration, setDuration] = useState<string>('');

    // 작성한 날짜
    let writeDate = ''; // 초기값 설정

    if (props.date) { // props.date 값이 존재하는 경우
        if (props.type === StreamType.MEAL_DIET) { // props.type이 'mealDiet'인 경우
            writeDate = moment(props.date).format('yyyy.MM.DD');
        } else if (props.type === 'classBoard') { // props.type이 'mealDiet'가 아닌 경우
            writeDate = moment(props.date).format('yyyy.MM.DD dd');
        } else {
            writeDate = moment(props.date).format('yyyy.MM.DD dd a hh:mm');
        }
    }

    // 타입 문자로 변형
    const setType = (type: string): string => {
        let typeStr;
        switch (type) {
            case StreamType.SURVEY:
                typeStr = '설문';
                break;
            case StreamType.NOTICE:
                typeStr = '공지';
                break;
            case 'schoolBoard':
            case StreamType.CLASS_BOARD:
                typeStr = '학급 게시판';
                break;
            case StreamType.MEAL_DIET:
                typeStr = '식단';
                break;
            case StreamType.FORMATTING_LIBRARY:
                typeStr = '서식';
                break;
            default:
                typeStr = '';
                break
       }
       return typeStr;
    }

    useEffect(() => {
        if (props.type !== 'survey' || !props.surveyStartDate || !props.surveyEndDate) {
            return;
        }
        if (props.isResponse) {
            setDuration('회신 완료');
            return;
        }

        const toDate = moment(new Date()).format('YYYYMMDD');
        if (props?.surveyStartDate <= toDate && props?.surveyEndDate >= toDate) {
            const duration = moment.duration(moment(moment(props?.surveyEndDate).format('YYYYMMDD')).diff(moment(moment(new Date()).format('YYYYMMDD')))).asDays();
            if (duration === 0) {
                setDuration('오늘 종료');
                return;
            } else {
                setDuration(`종료 ${duration}일 전`);
                return;
            }
        }

        if (props?.surveyStartDate > toDate) {
            setDuration('진행예정');
            return;
        }
        if (props?.surveyEndDate < toDate) {
            setDuration('설문종료');
            return;
        }
    }, [props])

	return (
		<header className='pb14 pt20'>
            <div className='flex mb4'>
                {
                    props.type === 'survey' ?
                    <>
                        <span className={duration === '설문종료' ? 'term mr5 close' : 'term mr5'}>{duration}</span>
                        <span className='type mr7'>{setType(props.type)}</span>
                    </> : <>
                            { props.type === StreamType.CLASS_BOARD && props.bAdminRecommend === '1' ? <span className={'recommend'}>중요</span> : null}
                            <span className='type mr7 notice'>{setType(props.type || '')}</span>
                        </>
                }
            </div>
			<h1 className='title'>
				{props.title}
                { props.type === StreamType.CLASS_BOARD && props.isNew ? <span className={'label-new'}>N</span> : null}
			</h1>
            <span className={props.type === StreamType.CLASS_BOARD ? 'date class-board' : 'date'}>
                {props.type === StreamType.CLASS_BOARD ? <>{props?.name}&nbsp;&nbsp;<i>|</i>&nbsp;&nbsp;</> : null}
                {writeDate || ''}
                {props.type === StreamType.MEAL_DIET ? <>&nbsp;&nbsp;<i>|</i>&nbsp;&nbsp;{props.schoolName}</> : null}
            </span>
		</header>
	)
}

export default Header;
