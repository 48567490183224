// @ts-ignore
// tslint:disable-next-line
if (typeof window == 'undefined') { (global as any).window = {location: {host: {}}}}

let baseDomain = '';
if (location.hostname.indexOf('coolmessenger.com') !== -1 || location.hostname.indexOf('coolalimi') !== -1 || location.hostname.indexOf('jirancomms.com') !== -1) {
    baseDomain = 'coolmessenger.com';
} else {
    baseDomain = 'coolschool.co.kr';
}
export let BASE_DOMAIN = baseDomain;

export const MEMBER_URL = 'https://member.' + BASE_DOMAIN;
export const DEV_MEMBER_URL = 'https://dev-member.' + BASE_DOMAIN;
export const LOCAL_MEMBER_URL = 'https://local-member.' + BASE_DOMAIN;
export const COOLSCHOOL_URL = 'https://www.coolschool.co.kr';
export const DEV_COOLSCHOOL_URL = 'https://dev.coolschool.co.kr';
export const LOCAL_COOLSCHOOL_URL = 'https://local.coolschool.co.kr';
export const COOL_LOGIN_URL = MEMBER_URL + '/login';
export const DEV_COOL_LOGIN_URL = DEV_MEMBER_URL + '/login';
export const LOCAL_COOL_LOGIN_URL = LOCAL_MEMBER_URL + '/login';
export const COOL_LOGIN_CALLBACK_URL =
    window.location.protocol + '//' + window.location.host + '/callback';
export const COOL_LOGIN_CALLBACK_FOR_SAMSTORY_URL =
    window.location.protocol + '//' + window.location.host + '/login';
export const COOL_LOGIN_CALLBACK_FOR_EMOTICON_URL =
  window.location.protocol + '//' + window.location.host + '/login';
export const SEARCH_URL = '//search.' + BASE_DOMAIN;
export const DEV_SEARCH_URL = '//dev-search.' + BASE_DOMAIN;
export const LOCAL_SEARCH_URL = '//local-search.' + BASE_DOMAIN;
export const COOL_LOGIN_CHECK_URL = `${MEMBER_URL}/loginCheck`;
export const SEARCH_MEMBER_BY_ACCESS_TOKEN = `${SEARCH_URL}/api/cool/_searchAuthMember`;
export const DEV_SEARCH_MEMBER_BY_ACCESS_TOKEN = `${DEV_SEARCH_URL}/api/cool/_searchAuthMember`;
export const LOCAL_SEARCH_MEMBER_BY_ACCESS_TOKEN = `${LOCAL_SEARCH_URL}/api/cool/_searchAuthMember`;
export const DEV_COOL_LOGIN_CHECK_URL = `${DEV_MEMBER_URL}/loginCheck`;
export const LOCAL_COOL_LOGIN_CHECK_URL = `${LOCAL_MEMBER_URL}/loginCheck`;
export const COOL_LOGOUT_URL = `${MEMBER_URL}/logout`;

let loginCallbackUrl = 'https://www.coolschool.co.kr/callback';
let devLoginCallbackUrl = 'https://dev.coolschool.co.kr/callback';
let localLoginCallbackUrl = 'https://local.coolschool.co.kr/callback';
if(baseDomain === 'coolmessenger.com') {
    loginCallbackUrl = 'https://school.coolmessenger.com/callback';
    devLoginCallbackUrl = 'https://dev-school.coolmessenger.com/callback';
    localLoginCallbackUrl = 'https://local-school.coolmessenger.com/callback';
}
export const COOLSCHOOL_CALLBACK_URL = loginCallbackUrl;
export const DEV_COOLSCHOOL_CALLBACK_URL = devLoginCallbackUrl;
export const LOCAL_COOLSCHOOL_CALLBACK_URL = localLoginCallbackUrl;

export const COOLFILE_URL = '//coolfile.' + BASE_DOMAIN + '/api';
export const UPLOAD_FILE_BY_ORIGIN = `${COOLFILE_URL}/uploadFileByOrigin`;

export const enum Env {
    LOCAL = 'local', DEV = 'dev', PROD = 'prod'
}

export const enum ServerName {
    COOLSCHOOL = 'coolschool',
    MEMBER = 'member',
    SEARCH = 'search'
}

export const FN_LOGOUT_URL = (url: string): string => {
    return `${url}/logout?client_id=:client_id`
};

export const FN_LOGOUT_PROC_URL = (url: string): string => {
    return `${url}/logoutProc`
};

export interface LogoutOpts {
    isRestoreLocation: boolean;
    isLogoutProc: boolean;
    logoutProcUrl: string | undefined;
}
