import {ParsedUrlQueryInput} from 'querystring';

export const SEARCH_URL = '//search.coolmessenger.com';
export const SEARCH_API_URL = `${SEARCH_URL}/api`;
export const SEARCH_MEMBER_BY_ACCESS_TOKEN = `${SEARCH_API_URL}/cool/_searchAuthMember`;
export const SEARCH_BBS_URL = `${SEARCH_API_URL}/coolStream/_searchStream/:streamId`;
export const SEARCH_COOLSUBSCRIBER_SAVE_URL = `${SEARCH_API_URL}/coolSubscriber/_save`;
export const SEARCH_NOTICE_BBS_URL = `${SEARCH_API_URL}/coolStream/_searchStream/:streamId`;
export const SEARCH_SCHOOL_INFO_URL = `${SEARCH_API_URL}/coolUSchool/school/_search`;

export const COOL_FILE_URL = '//coolfile.coolmessenger.com';
export const COOL_FILE_URL_API_URL = `${COOL_FILE_URL}/api`;
export const COOL_FILE_DELETE_API_URL = `${COOL_FILE_URL_API_URL}/delete/:pathToken`;
export const COOL_FILE_UPLOAD_SIGNITURE_URL = `${COOL_FILE_URL_API_URL}/uploadFileByCoolidx`;
export const COOL_FILE_UPLOAD_BINARY_URL_BY_COOLIDX = `${COOL_FILE_URL_API_URL}/uploadBinaryFileByCoolIdx/:coolIdx`;
export const COOL_FILE_APP_URL = `${COOL_FILE_URL_API_URL}/download`;

export const ALIMI_URL = 'https://www.coolalimi.com';
export const ALIMI_INVITATION_URL = `${ALIMI_URL}/invitation`;

export const MEMBER_URL = 'https://member.coolmessenger.com';
export const MEMBER_URL_API = `${MEMBER_URL}/api`;
export const MEMBER_SIGN_SAVE_URL_API = `${MEMBER_URL}/api/saveMemberImage`;
export const MEMBER_SIGN_GET_URL_API = `${MEMBER_URL}/api/getMemberImage`;

export const CLIENT_ID = `NjM2YzY5NjU2ZTc0NWY2OTY0M2E0MzZmNmY2YzQxNmM2MTcyNmQ=`;

export const COMMENT_SE_JS_URL = '//resource.coolmessenger.com/coolschool/resources/js/comment-se.app.js';
export const COMMENT_SE_STYLE_URL = '//cdn.jsdelivr.net/npm/alertifyjs@1.13.1/build/css/themes/bootstrap.min.css';
export const ALERTIFY_JS_URL = '//cdn.jsdelivr.net/npm/alertifyjs@1.11.4/build/alertify.min.js';
export const ALERTIFY_STYLE_URL = '//cdn.jsdelivr.net/npm/alertifyjs@1.11.4/build/css/alertify.min.css';
export const POPPER_JS_URL = '//cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js';
export const EMOTICON_BOOTSTRAP_JS_URL = '//stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js';

export enum SurveyType {
    singleSingle = 'singleSingle',
    singleMulti = 'singleMulti',
    shortAnswer = 'shortAnswer',
    essayAnswer = 'essayAnswer',
    dateAnswer = 'dateAnswer',
    fileAnswer = 'fileAnswer',
}

export const TOKEN_NAME = 'authenticationToken';

export enum ServiceType {
    ALIMI = 'alimi'
}

export enum StreamType {
    NOTICE = 'notice', SURVEY = 'survey', MEAL_DIET = 'mealDiet', CLASS_BOARD = 'classBoard', FORMATTING_LIBRARY = 'formattingLibrary'
}

export enum SubServiceType {
    SURVEY_RESPONSE = 'surveyResponse'
}

export interface ISurveySearchProps extends ParsedUrlQueryInput  {
    msgId?: string;
    contactId?: string;
    metaId?: string;
}

export interface ISurveyDate {
    surveyStartDate: string;
    surveyEndDate: string;
}

export interface ISurveyDateInfos {
    startDate: string;
    endDate: string;
    startDateHour: string;
    startDateMinute: string;
    endDateHour: string;
    endDateMinute: string;
}

export interface IHeaderProps {
    type?: string;
    title?: string;
    date?: string;
    modifyDate?: string;
    receiver?: string;
    surveyStartDate?: string;
    surveyEndDate?: string;
    isResponse?: boolean;
    schoolName?: string;
    streamId?: string;
    writeDate?: string;
    bAdminRecommend?: string;
    name?: string;
    isNew?: boolean;
    comment?: string;
}

export interface ISignatureProps {
    signatureUrl: string;
    isSignatureCheck: boolean;
    isValidationSignCheck: boolean;
    isSignWrite: boolean;
    showSign: boolean;
    signBtnToggle: boolean;
    signatureInfo?: boolean;
}

export interface ISurvey {
    surveyQuestion?: [];
    surveyType: string;
    title: string;
    description?: string;
    datepickerType?: string;
}

export interface ISurveyAnswer {
    title: string;
    surveyType: string;
    description?: string;
    index: number;
    answer?: string | number;
    datepickerType?: string;
    surveyQuestion?: IAnswer[];
    isEssential?: boolean;
}

export interface IAnswer {
    content: string;
    isChecked: boolean;
    etc?: boolean;
}

export type IDatepickerProps = {
    dateType: string;
    answer: string | number | undefined;
    index: number;
}

export interface ISurveyInfoProps {
    isSurveyTerm: boolean;
    surveyTermTxt: string;
    isValidationCheck: boolean;
    surveyData: ISurvey[],
}

export interface IUserInfoProps {
    config?: {[key: string]: any};
    data?: {[key: string]: any};
    headers?: {[key: string]: any};
    request?: {[key: string]: any};
    status?: number;
    statusText?: string;
}

export interface ISignatureParams {
    serviceType?: string;
    fileName?: string;
    imgData?: string
}

export interface IDatepickerOptionProps {
    sideBySide?: boolean;
    dayViewHeaderFormat?: string;
    format?: string;
    defaultDate?: Date;
    locale?: string;
    icons?: {
        next: string;
        previous: string;
        up: string;
        down: string;
    };
}
