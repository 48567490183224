import {useSetRecoilState} from 'recoil';
import {IUserInfoProps} from '../app.constant';
import {userInfoAtom} from '../atoms/atoms';
import {useEffect} from 'react';
import axios from 'axios';
import {getToken, getUserDataForToken} from '../service/common';

/** 토큰 값으로 로그인 유저 데이터 가져오기 */
export const useGetUserData = (): void => {
    const setUserInfo = useSetRecoilState<IUserInfoProps>(userInfoAtom);

    useEffect(() => {
        const fetchData = async (): Promise<void> => {
            const token = getToken();

            // 유저 데이터 가져오는 함수 실행
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            const response = await getUserDataForToken(token);
            setUserInfo(response);
        };

        fetchData();
    }, [setUserInfo]);
};
